import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

const goHome = ['Result', 'Reopen'];

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/shoppingCar',
    name: 'ShoppingCar',
    component: () => import('@/views/ShoppingCar'),
  },
  //确认订单页
  {
    path: '/preOrder',
    name: 'PreOrder',
    component: () => import('@/views/PreOrder'),
  },
  // 订单列表
  {
    path: '/orderList',
    name: 'OrderList',
    component: () => import('@/views/OrderList/index'),
    meta: {
      isAlive: true,
      saveSrollTop: true,
    },
  },
  // 订单详情
  {
    path: '/orderDetails',
    name: 'orderDetails',
    component: () => import('@/views/OrderDetails/index'),
  },
  // 微信外卖 订单详情
  {
    path: '/detailsDelivery',
    name: 'detailsDelivery',
    component: () => import('@/views/OrderDetails/detailsDelivery'),
  },
  {
    path: '/refund',
    name: 'refund',
    component: () => import('@/views/Refund/index'),
  },
  {
    path: '/voice',
    name: 'voice',
    component: () => import('@/views/Voice/index'),
  },
  {
    path: '/choose',
    name: 'choose',
    component: () => import('@/views/Voice/Choose'),
  },
  // 新石器网络平台用户协议
  {
    path: '/userAgreement',
    name: 'UserAgreement',
    component: () => import('@/views/UserAgreement'),
  },
  // 新石器准时达用户协议
  {
    path: '/OpmUserAgreement',
    name: 'OpmUserAgreement',
    component: () => import('@/views/OpmUserAgreement'),
  },
  // 新石器无人车APP隐私权政策
  {
    path: '/userPrivacy',
    name: 'UserPrivacy',
    component: () => import('@/views/UserPrivacy'),
  },
  // 新石器准时达APP隐私权政策
  {
    path: '/OpmUserPrivacy',
    name: 'OpmUserPrivacy',
    component: () => import('@/views/OpmUserPrivacy'),
  },
  {
    path: '/reopen', // 重开柜门
    name: 'Reopen',
    component: () => import('@/views/Reopen'),
  },
  {
    path: '/coupon', // 优惠券
    name: 'Coupon',
    component: () => import('@/views/Coupon'),
  },
  {
    path: '/invalidCoupon', // 优惠券
    name: 'InvalidCoupon',
    component: () => import('@/views/InvalidCoupon'),
  },
  {
    path: '/result', // 支付结果页
    name: 'Result',
    component: () => import('@/views/Result'),
  },
  // app唤起下载
  {
    path: '/downloadApp',
    name: 'DownloadApp',
    component: () => import('@/views/DownloadApp'),
  },
  // 优惠券下载App
  {
    path: '/downloadAppCoupons',
    name: 'DownloadAppCoupons',
    component: () => import('@/views/DownloadAppCoupons'),
  },
  // 优惠券列表-登陆注册
  {
    path: '/activityCoupon',
    name: 'activityCoupon',
    component: () => import('@/views/activityCoupon'),
  },
  // 我的订单列表
  {
    path: '/myOrderList',
    name: 'myOrderList',
    component: () => import('@/views/MyOrderList/index'),
    meta: { loginRequest: 'true' },
  },
  // 已投柜订单列表
  {
    path: '/InstalledOrderList',
    name: 'InstalledOrderList',
    component: () => import('@/views/MyOrderList/InstalledOrderList'),
    meta: { loginRequest: 'true' },
  },
  // 搜索我的订单列表
  {
    path: '/searchList',
    name: 'searchList',
    component: () => import('@/views/MyOrderList/SearchList'),
  },
  // 订单详情APP
  {
    path: '/orderDetailApp',
    name: 'orderDetailApp',
    component: () => import('@/views/orderDetailApp'),
  },
  //  订单已过期
  {
    path: '/orderExpired',
    name: 'orderExpired',
    component: () => import('@/views/orderExpired'),
  },
  // 开门投柜
  {
    path: '/openDoor',
    name: 'openDoor',
    component: () => import('@/views/MyOrderList/OpenDoor'),
  },
  // 站点管理（地图）
  {
    path: '/stationManagement',
    name: 'stationManagement',
    meta: { isAlive: true },
    component: () => import('@/views/StationManagement'),
  },
  // 站点管理（地图）
  {
    path: '/stationManagementNew',
    name: 'stationManagementNew',
    meta: { isAlive: true },
    component: () => import('@/views/StationManagementNew'),
  },
  // 站点管理（列表）
  {
    path: '/stationList',
    name: 'stationList',
    component: () => import('@/views/StationList'),
  },
  // 站点管理 - 查看停车区指引
  {
    path: '/parkingGuide',
    name: 'parkingGuide',
    component: () => import('@/views/parkingGuide/index.vue'),
  },
  // 数据中心
  {
    path: '/dataCenter',
    name: 'dataCenter',
    component: () => import('@/views/dataCenter'),
  },
  // 智能驾驶管理
  {
    path: '/intelligentDrivingManage',
    name: 'intelligentDrivingManage',
    component: () => import('@/views/IntelligentDrivingManage/index'),
  },
  // 停车微调
  {
    path: '/parking',
    name: 'parking',
    component: () => import('@/views/Parking/index'),
  },
  // 任务记录
  {
    path: '/taskRecord',
    name: 'TaskRecord',
    component: () => import('@/views/VehicleTask/TaskRecord'),
    meta: {
      isAlive: true,
    },
  },
  // 任务详情
  {
    path: '/missionDetail',
    name: 'missionDetail',
    component: () => import('@/views/VehicleTask/MissionDetail'),
    meta: {
      // isAlive: true,
    },
  },
  // 灯光控制
  {
    path: '/lightControl',
    name: 'lightControl',
    component: () => import('@/views/LightControl'),
  },
  // 场内挪车
  {
    path: '/onSiteParking',
    name: 'onSiteParking',
    component: () => import('@/views/Parking/OnSiteParking'),
  },
  // 选择装车点
  {
    path: '/choosePoint',
    name: 'choosePoint',
    component: () => import('@/views/Parking/choosePoint'),
  },
  // 订单详情APP顺丰
  {
    path: '/missionDetailAppSF',
    name: 'missionDetailAppSF',
    component: () => import('@/views/missionDetailAppSF'),
  },
  // 故障上报
  {
    path: '/FaultReporting',
    name: 'FaultReporting',
    component: () => import('@/views/FaultReporting'),
  },
  // 叫车
  {
    path: '/CarHailingPage',
    name: 'CarHailingPage',
    component: () => import('@/views/CarHailing/index'),
  },
  // 叫车记录
  // {
  //   path: '/CarHailingRecord',
  //   name: 'CarHailingRecord',
  //   component: () => import('@/views/CarHailing/Record')
  // },
  // 实时任务概览
  {
    path: '/TaskOverview',
    name: 'TaskOverview',
    component: () => import('@/views/TaskOverview/index'),
  },
  // X3Pro安防车互动页面
  {
    path: '/SecurityVehicle',
    name: 'SecurityVehicle',
    component: () => import('@/views/SecurityVehicle'),
  },
  {
    path: '/controlSecurity',
    name: 'controlSecurity',
    component: () => import('@/views/SecurityVehicle/controlSecurity'),
  },
  // 泊车工具
  {
    path: '/parkingTool',
    name: 'parkingTool',
    component: () => import('@/views/parkingTool/index.vue'),
  },
  // 泊车任务详情
  {
    path: '/parkingRecord',
    name: 'parkingRecord',
    component: () => import('@/views/parkingTool/ParkingRecord.vue'),
  },
  // 地图标绘
  {
    path: '/logisticsMap',
    name: 'logisticsMap',
    component: () => import('@/views/LogisticsMap/index.vue'),
    meta: {
      isAlive: true,
    },
  },
  // 图片上传
  {
    path: '/logisticsMap/imageUpload',
    name: 'imageUpload',
    component: () => import('@/views/LogisticsMap/pages/ImageUpload.vue'),
  },
  // 位置搜索
  {
    path: '/logisticsMap/locationSearch',
    name: 'locationSearch',
    component: () => import('@/views/LogisticsMap/pages/LocationSearch.vue'),
  },
  // 地图标绘-网格搜索
  {
    path: '/logisticsMap/SearchPark',
    name: 'logisticsMapSearchPark',
    component: () => import('@/views/LogisticsMap/pages/SearchPark.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  if (goHome.includes(to.name)) {
    window.goBack = () => {
      window.location.href = 'neolixjs://webview?url=back';
    };
  } else {
    window.goBack = () => {
      router.go(-1);
      Vue.$loading.end();
    };
  }
});

export default router;
