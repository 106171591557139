import sha1 from 'crypto-js/sha1';

// const TOKEN = "fb8305f6-c619-4f13-a74e-2eb37a935d34";
// const TOKEN = "neolixxcx123";
// const TOKEN = '94a973a7-978a-47aa-bfa5-56da8d683089';
// const TOKEN = 'ccff9a19-5b71-43f6-9282-3fe9ff4faa99';
const TOKEN = 'ac19ffed-1edd-4803-9ba3-ec8e572ec334';
const SECRET = '12edfbaf-3b34-555b-9b7b-0d1e5904d670';
const USERID = 1;

export function getToken() {
  return sessionStorage.getItem('_token');
  // return "neolixxcx123";
}

export function setToken(token, appSecret, userId) {
  if (process.env.NODE_ENV === 'development') {
    sessionStorage.setItem('_token', TOKEN);
    appSecret && sessionStorage.setItem('_secret', SECRET);
    userId && sessionStorage.setItem('_userId', USERID);
    return;
  }

  if (token == getToken() || !token) return;
  sessionStorage.setItem('_token', token);
  appSecret && sessionStorage.setItem('_secret', appSecret);
  // userId && sessionStorage.setItem("_userId", String(userId));
}

export function getAppSecret() {
  return sessionStorage.getItem('_secret');
  // return "12edfbaf-3b34-555b-9b7b-0d1e5904d670";
}

// 鉴权 生成sha1字符串
const sha1Crypto = (timestamp, nonce) =>
  sha1(
    [timestamp, nonce, getAppSecret()].sort().reduce((a, b) => a + '' + b),
    ''
  )
    .toString()
    .toUpperCase();

const randomNumber = () => parseInt((Math.random() * 9 + 1) * 10000);

export const encrypt = () => {
  const nonce = randomNumber();
  const timestamp = new Date().getTime();

  const signature = sha1Crypto(timestamp, nonce);
  return { nonce, timestamp, signature };
};
